<template>
   <div class="col-12 px-0 mb-1" :class="toggleLojas ? 'main-container' : ''" ref="produto">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="w-max-content pe-12 d-none d-xl-block">
                  <img :src="produto.banner == null ? '' : produto.banner" class="rounded" alt="icon" @error="imageError" height="50" />
               </div>
               <div class="col-sm text-center text-sm-start cursor-pointer" @click="toggleLojas = !toggleLojas;">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ produto.nome }}</h1>
                        <p class="font-10 mb-2 limitador-1 text-secondary">
                           <span><i class="far fa-hashtag font-9 me-1"></i>{{ produto.id }}</span>
                           <span class="ms-3">
                              <i class="far fa-tags font-9 me-1"></i>
                              <span>{{ produto.familia == null || String(produto.familia).trim().length == 0 ? 'Sem família' : produto.familia }}</span>
                           </span>
                        </p>
                     </div>
                     <div class="col">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-barcode color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-xl-inline"> Código(s):</strong>
                           <span> {{ produto.codigos.filter(e => e.ean != null).length == 0 ? 'Sem código' : String(produto.codigos.filter(e => e.ean != null).map(e => e.ean)).replace(/,/g, ", ") }}</span>
                        </p>
                     </div>
                     <div class="col-6 d-none d-md-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-bookmark color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Marca:</strong>
                           <span> {{ produto.marca == null || String(produto.marca).trim().length == 0 ? 'Sem marca' : produto.marca }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="w-max-content text-center font-16 mx-auto mx-sm-0 mt-2 mt-sm-0">
                  <div class="btn-icone color-theme">
                     <span @click="addTemp(null)"><i class="fal fa-plus d-block"></i><small>Incluir tudo</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="sub-container" v-if="toggleLojas">
         <div class="col-12 mb-3">
            <h1 class="font-15 mt-1 mb-0"><i class="far fa-store font-12 color-theme me-2"></i>Lojas</h1>
         </div>

         <loja v-for="(loja, index) in produto.lojas" :key="index" :loja="loja" :index="index" :produto="produto" :tempPedido="tempPedido" 
            @addTemp="addTemp($event)" @removeTemp="removeTemp($event)" />
            
         <div class="col-12 mt-4 mb-5 text-center" v-if="produto.lojas.length == 0">
            <i class="fal fa-store-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
            <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhuma loja encontrada</h4>
         </div>
      </div>
   </div>
</template>

<script>

import loja from '@/components/compras/pedidosCriacao/Loja.vue'

export default {
   name: 'Produto',
   props: ['produto', 'index', 'tempPedido'],
   data: function () {
      return {
         toggleLojas: false
      }
   },
   components: {
      loja
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      addTemp : function (loja) {
         if (loja) {
            this.$emit('addTemp', {"id": this.produto.id, "idLoja": loja.id, "pedidoSugerido": loja.pedidoSugerido, "custo": loja.custo})
         } else {
            this.produto.lojas.forEach(l => this.$emit('addTemp', {"id": this.produto.id, "idLoja": l.id, "pedidoSugerido": l.pedidoSugerido, "custo": l.custo}));
         }
      },
      removeTemp : function (loja) {
         this.$emit('removeTemp', {"idProduto": this.produto.id, "idLoja": loja.id})
      }
   }
}

</script>