<template>
   <div class="col-12">
      <div class="card mb-1" :class="index % 2 == 0 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-md text-center text-md-start">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ loja.nomeFantasia }}</h1>
                        <p class="font-10 mb-1 limitador-1 text-secondary">
                           <span><i class="far fa-hashtag font-9 me-1"></i>{{ loja.id }}</span>
                           <span class="ms-3">
                              <i class="far fa-store font-9 me-1"></i>
                              <span>{{ loja.numeroLoja == null || String(loja.numeroLoja).trim().length == 0 ? 'Sem número' : loja.numeroLoja }}</span>
                           </span>
                        </p>
                     </div>
                     <div class="col-6 col-xl-4 d-none d-sm-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-dollar-sign color-theme font-10 me-1"></i><strong class="subtitle me-1"> Custo: </strong>
                           <span><small>R$ </small>{{ loja.custo == null ? '0,00' : parseFloat(loja.custo).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                        </p>
                     </div>
                     <div class="col-6 col-xl-4 d-none d-md-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-dollar-sign color-theme font-10 me-1"></i><strong class="subtitle me-1"> Preço: </strong>
                           <span><small>R$ </small>{{ loja.preco == null ? '0,00' : parseFloat(loja.preco).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                        </p>
                     </div>
                     <div class="col-sm-6 col-md-12 col-xl-4">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-box font-10 me-1" :class="parseFloat(loja.estoqueAtual) < parseFloat(loja.estoqueMinimo) ? 'text-red': 'color-theme'"></i>
                           <strong class="subtitle me-1"> Estoque: </strong>
                           <span>{{ loja.estoqueAtual == null ? 0 : parseFloat(loja.estoqueAtual).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits: 3}) }}</span>
                           <span> (Mínimo {{ loja.estoqueMinimo == null ? 0 : parseFloat(loja.estoqueMinimo).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits: 3}) }})</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="w-max-content text-center mx-auto ms-md-0 mt-2 mt-md-0">
                  <label><i class="far fa-box color-theme font-9 me-1"></i> Quantidade</label>
                  <input type="text" class="form-control input-sm wpx-115" v-model="loja.pedidoSugerido" />
               </div>
               <div class="w-max-content text-center font-16 mx-auto me-md-0 ms-md-3 mt-2 mt-md-0">
                  <div class="btn-icone" :class="tempPedido.flatMap(t => t.produtos).findIndex(p => p.idLoja == loja.id && p.id == produto.id) != -1 ? 'pedido' : 'color-theme'">
                     <span @click="toggleProduto" class="ps-3"><i class="far fa-tasks d-block"></i><small>Pedido</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Loja',
   props: ['loja', 'index', 'produto', 'tempPedido'],
   watch: {
      'loja.pedidoSugerido' : function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': this.produto.isDecimal ? 3 : 0}).then((valor) => {
            this.loja.pedidoSugerido = valor
         })
      }
   },
   methods: {
      toggleProduto : function () {
         let indexTemp = this.tempPedido.flatMap(t => t.produtos).findIndex(p => p.idLoja == this.loja.id && p.id == this.produto.id)
         
         if (indexTemp == -1) {
            this.$emit('addTemp', this.loja)
         } else {
            this.$emit('removeTemp', this.loja)
         }
      }
   },
   mounted() {
      this.loja.pedidoSugerido = this.produto.isDecimal ? parseFloat(this.loja.pedidoSugerido).toFixed(3) : parseInt(this.loja.pedidoSugerido)
   }
}

</script>

<style scoped>

.color-theme .fa-tasks::after,
.pedido .fa-tasks::after {
   font-weight: 400;
   position: absolute;
   top: 0px;
   right: 15px;
   font-size: 80%;
}

.pedido:hover .fa-tasks::after {
   top: -3px;
   font-size: 100%;
}

.color-theme .fa-tasks::after {
   content: "\f067"; /* PLUS */
}

.btn-icone.pedido span i,
.btn-icone.pedido span small {
   color: #33cc66 !important;
}

.btn-icone.pedido:hover span i,
.btn-icone.pedido:hover span small {
   color: #c23e4a !important;
}

.pedido .fa-tasks::after {
   content: "\f00c"; /* CHECK */
}

.pedido:hover .fa-tasks::after {
   content: "\f00d"; /* TIMES */
}

</style>