<template>
   <div class="col-12">
      <div class="card mb-1" :class="index % 2 == 0 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-md text-center text-md-start">
                  <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ produto.nome }}</h1>
                  <p class="font-10 mb-1 limitador-1 text-secondary">
                     <span class="me-3"><i class="far fa-hashtag font-9 me-1"></i>{{ produto.id }}</span>
                     <span><i class="far fa-store font-8 me-1"></i>{{ produto.nomeLoja == null || String(produto.nomeLoja).trim().length == 0 ? 'Não definido' : produto.nomeLoja }}</span>
                     <small class="mx-1">|</small>
                     <span><i class="far fa-tag font-8 me-1"></i>{{ produto.numeroLoja }}</span>
                  </p>
                  <p class="font-12 mb-0 limitador-1">
                     <i class="far fa-box font-10 me-1" :class="parseFloat(produto.estoqueAtual) < parseFloat(produto.estoqueMinimo) ? 'text-red': 'color-theme'"></i>
                     <strong class="subtitle me-1"> Estoque: </strong>
                     <span>{{ produto.estoqueAtual == null ? 0 : parseFloat(produto.estoqueAtual).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits: 3}) }}</span>
                     <span> (Mínimo {{ produto.estoqueMinimo == null ? 0 : parseFloat(produto.estoqueMinimo).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits: 3}) }})</span>
                  </p>
               </div>
               <div class="w-max-content text-center mx-auto ms-md-0 mt-2 mt-md-0">
                  <label><i class="far fa-dollar-sign color-theme font-9 me-1"></i> Custo</label>
                  <input type="text" class="form-control input-sm wpx-115" v-model="produto.custo" @blur="salvar" />
               </div>
               <div class="w-max-content text-center mx-auto ms-md-0 mt-2 mt-md-0">
                  <label><i class="far fa-box color-theme font-9 me-1"></i> Quantidade</label>
                  <input type="text" class="form-control input-sm wpx-115" v-model="produto.pedidoSugerido" @blur="salvar" />
               </div>
               <div class="w-max-content text-center font-16 mx-auto me-md-0 ms-md-3 mt-2 mt-md-0">
                  <div class="btn-icone text-red">
                     <span @click="remover"><i class="far fa-trash"></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'ProdutoLoja',
   props: ['produto', 'index'],
   watch: {
      'produto.pedidoSugerido' : function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': this.produto.isDecimal ? 3 : 0}).then((valor) => {
            this.produto.pedidoSugerido = valor
         })
      },
      'produto.custo' : function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 2}).then((valor) => {
            this.produto.custo = valor
         })
      }
   },
   methods: {
      salvar : function () {
         if (parseFloat(this.produto.pedidoSugerido) > 0 && parseFloat(this.produto.custo) > 0) {
            this.$emit('salvar', this.produto)
         }
      },
      remover : function () {
         this.$emit('removeTemp', {"idProduto": this.produto.id, "idLoja": this.produto.idLoja})
      }
   },
   mounted() {
      this.produto.pedidoSugerido = this.produto.isDecimal ? parseFloat(this.produto.pedidoSugerido).toFixed(3) : parseInt(this.produto.pedidoSugerido)
      this.produto.custo = this.produto.custo == null ? '0.00' : parseFloat(this.produto.custo).toFixed(2)
   }
}

</script>